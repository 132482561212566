<template>
  <div class="main">
    <!-- 搜索栏 -->
    <div class="list-tool-bar">
      <el-button-group class="action">
        <el-button @click="winShow = true" type="primary">{{ t('table.create') }}</el-button>
        <el-button @click="onDelete" type="primary" plain>{{ t('form.delete') }}</el-button>
      </el-button-group>
      <div class="search">
        <div class="search_item">
          <el-input
            v-model="searchData.search"
            @input.native="doSearch"
            :prefix-icon="Search"
            :placeholder="t('form.search_placeholder')"
            class="search-input"
            clearable />
        </div>
        <div class="search_item search_time">
          <div class="time_select">
            <el-select v-model="timeType">
              <el-option :label="t('table.creation_time')" value="1" />
              <el-option :label="t('table.login_time')" value="2" />
            </el-select>
          </div>
          <!-- 时间选择框 -->
          <custem-time ref="timeRef" @setTime="setTime" />
        </div>
      </div>
    </div>
    <!-- 运营管理 -->
    <custem-table :tableInfo="tableInfo" :tableLoading="tableLoading" @onSelectData="onSelectData" @getList="getList"></custem-table>
    <el-dialog
      v-model="winShow"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="title"
      @close="onClose"
      width="900px">
      <create-user
        ref="formRef"
        addType="GeneralAdmin"
        :winShow="winShow"
        @getList="getList"
        @onClose="onClose" />
    </el-dialog>
  </div>
</template>

<script setup>

import { Search } from '@element-plus/icons-vue'
import { userManagement } from '@/utils/api'
import { onMounted, reactive, ref, inject, computed, nextTick } from 'vue'
import { ElMessage } from "element-plus"

const t = inject('t')
const formRef = ref() // 表单对象
const delList = ref([]) // 删除数据
const tableLoading = ref(false)
const winShow = ref(false)
const edit_id = ref('')
const searchData = ref({
	filter_role_alias: 'GeneralAdmin'
})
const timeType = ref('1')
// 弹框标题
const title = computed(() => {
  return !!edit_id.value ? `${t('table.modify')} ${t('role.role2')}` : `${t('table.create')} ${t('role.role2')}`
})
// 删除
const deleteData = (row) => {
  const { id } = row;
  if (!!!id) return
  userManagement.roleUserDelete(id).then(res => {
    ElMessage.success(t('table.del_success'))
    delList.value = []
    tableInfo.data = tableInfo.data.filter((ele) => ele.id !== id);
  })
}
// 编辑
const editData = async (row) => {
  const { id } = row
  if (!!!id) return
  winShow.value = true
  await nextTick()
  edit_id.value = id
  formRef.value.getUserDetail(edit_id.value)
}
// 多选删除
const onDelete = () => {
  if (!!!delList.value.length) {
    ElMessage.warning(t('table.select_del'))
    return
  }
  delList.value.forEach(item => {
    deleteData(item)
  })
}

// 接收多选数据
const onSelectData = (data) => {
  delList.value = data;
}

// 表格信息
const tableInfo = reactive({
  selection: true, // 选择框列
  header: [
    { // 名称
      key: 'name',
      label: t('user.name')
    },
    { // 注册日期
      key: 'created_at',
      width: '180px',
      sortable: true,
      label: t('table.registration_time')
    },
    { // 最后登录
      key: 'login_at',
      width: '180px',
      sortable: true,
      label: t('table.last_login_time')
    },
    {
      key: 'login_at',
      label: t('table.action'),
      width: '110px',
      fixed: 'right',
      handleFun: [
        {
          name: t('table.modify'),
          fn: editData
        },
        {
          name: t('form.delete'),
          type: 'delete',
          content: t('form.sure_del'),
          fn: deleteData
        }
      ]
    }
  ]
})

const getList = async (search = {}) => {
  tableLoading.value = true
  try {
    const res = await userManagement.roleUserList({
      ...searchData.value
    })
    tableInfo.data = res.items
    tableInfo.totalPage = res.total
    tableInfo.pageSize = res.per_page
    tableLoading.value = false
  } catch (error) {
    tableLoading.value = false
  }
}

const onClose = () => {
  if (formRef.value) formRef.value.clearForm()
  winShow.value = false
}

const setTime = (timeDate) => {
	if (timeDate.length) {
    searchData.value = {
      ...searchData.value,
      filter_login_at: timeType.value === '1' ? '' : timeDate[0],
      filter_created_at: timeType.value === '1' ? timeDate[0] : '',
      filter_end_at: timeDate[1]
    }
	}
	doSearch()
}

// 搜索事件
const doSearch = () => {
	for (let key in searchData.value) {
		if (!!!searchData.value[key]) {
			delete searchData.value[key]
		}
	}
	getList()
}

onMounted(() => {
  getList()
})
</script>